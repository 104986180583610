import { createBrowserRouter } from "react-router-dom";
import { ErrorPage } from "./error-page";
import { MyActivityPage } from "./components/my-activity/MyActivityPage";
import { queryProviderComponent } from "./components/common/PhotoProvider";
import { GET_LIKED_PHOTOS } from "./queries/getLikedPhotos";
import { GET_COMMENTED_PHOTOS } from "./queries/getCommentedPhotos";
import { ModerationPage } from "./components/moderate/ModerationPage";
import { GalleryPage } from "./components/gallery/GalleryPage";
import { VotingPage } from "./components/vote/VotingPage";
import { Layout } from "./components/layouts/Layout";
import { MyPhotosPage } from "./components/my-photos/MyPhotosPage";
import { ModerateIndex } from "./components/moderate/ModerateIndex";
import { ModerateComments } from "./components/moderate/ModerateComments";
import { ManageRejections } from "./components/moderate/ManageRejections";
import { voteSubpageWithQuery } from "./components/vote/VoteSubpage";
import { GET_VOTEABLE_PHOTOS_BY_CATEGORY } from "./queries/getRandomPhotos";
import { ContestClosedPage } from "./components/contest-closed/ContestClosedPage";
import { ModeratePhotos } from "./components/moderate/ModeratePhotos";
import { ModerateVotesDashboard } from "./components/moderate/ModerateVotesDashboard";
import { LoginPicker } from "./components/authentication/LoginPicker";
import { UserBlocked } from "./components/authentication/UserBlocked";
import { UserNotConfirmed } from "./components/authentication/UserNotConfirmed";
import { UserNotYetApproved } from "./components/authentication/UserNotYetApproved";
import { LoginMicrosoft } from "./components/authentication/LoginMicrosoft";
import { AuthPage } from "./components/authentication/AuthPage";
import { ModerateUsers } from "./components/moderate/ModerateUsers";
import { Logout } from "./components/authentication/Logout";
import { ManageAccountPage } from "./components/authentication/manage/ManageAccountPage";
import { ProfilePage } from "./components/profile/ProfilePage";

const LikedPhotosProvider = queryProviderComponent(
  GET_LIKED_PHOTOS,
  "cache-and-network",
  "likedPhotos"
);
const CommentedPhotosProvider = queryProviderComponent(
  GET_COMMENTED_PHOTOS,
  "cache-and-network",
  "commentedPhotos"
);
const VoteGallery = voteSubpageWithQuery({
  query: GET_VOTEABLE_PHOTOS_BY_CATEGORY,
  photosDataKey: "photosVoteableByCategoryRandom",
});

export const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <GalleryPage />,
      },
      {
        path: "my-photos",
        element: <MyPhotosPage />,
      },
      {
        path: "my-activity",
        element: <MyActivityPage />,
        children: [
          {
            path: "",
            element: <LikedPhotosProvider />,
          },
          {
            path: "liked",
            element: <LikedPhotosProvider />,
          },
          {
            path: "commented",
            element: <CommentedPhotosProvider />,
          },
        ],
      },
      {
        path: "moderate",
        element: <ModerationPage />,
        children: [
          {
            path: "",
            element: <ModerateIndex />,
          },
          {
            path: "photos",
            element: <ModeratePhotos />,
          },
          {
            path: "comments",
            element: <ModerateComments />,
          },
          {
            path: "rejections",
            element: <ManageRejections />,
          },
          {
            path: "voting",
            element: <ModerateVotesDashboard />,
          },
          {
            path: "users",
            element: <ModerateUsers />
          }
        ],
      },
      {
        path: "vote",
        element: <VotingPage />,
        children: [
          {
            path: "",
            element: <VoteGallery />,
          },
        ],
      },
      // {
      //   path: "prizes",
      //   element: <PrizesPage />,
      // },
      {
        path: "closed",
        element: <ContestClosedPage />,
      },
      {
        path: "auth",
        element: <AuthPage />,
        children: [
          {
            path: "login",
            element: <LoginPicker />,
          },
          /* {
            path: "login/email",
            element: <LoginEmailPassword />,
          }, */
          {
            path: "login/microsoft",
            element: <LoginMicrosoft />,
          },
          /* {
            path: "register",
            element: <RegisterNewUser />
          },
          {
            path: "forgot-password",
            element: <ForgotPassword />
          },
          {
            path: "resend-confirm-email",
            element: <ResendConfirmEmail />
          },
          {
            path: "confirm-email",
            element: <ConfirmEmail />
          },
          {
            path: "confirm-register",
            element: <ConfirmRegister />
          },
          {
            path: "reset-password",
            element: <ResetPassword />
          }, */
          {
            path: "blocked",
            element: <UserBlocked />,
          },
          {
            path: "not-confirmed",
            element: <UserNotConfirmed />,
          },
          {
            path: "pending-approval",
            element: <UserNotYetApproved />,
          },
          {
            path: "logout",
            element: <Logout />
          }
        ],
      },
      {
        path: "account",
        element: <ManageAccountPage />
      },
      {
        path: "profile/:id",
        element: <ProfilePage />
      }
    ],
  },
]);
