import React, { useContext, useRef, useState } from "react";
import { DocumentNode } from "graphql";
import { IndexQuery, Vote } from "../../types";
import { ScrollableElementContext } from "../../context/ScrollableElementContext";
import { CategoriesContext } from "../../context/CategoriesContext";
import { AuthContext } from "../../context/AuthContext";
import { useQuery } from "@apollo/client";
import { VoteSettingsContext } from "../../context/VoteSettingsContext";
import { GET_VOTES_BY_USER } from "../../queries/getVotesByUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ARROW_RIGHT, ARROW_LEFT, APPROVED_ICON } from "../../constants/icons";
import { CategoryIcon } from "../common/CategoryIcon";
import { VoteCategory } from "./VoteCategory";

interface VoteSubpageProps {
  query: DocumentNode;
  photosDataKey: string;
}

export const VoteSubpage: React.FC<VoteSubpageProps> = ({
  query,
  photosDataKey,
}) => {
  const categories = useContext(CategoriesContext);
  const [categoryIndex, setCategoryIndex] = useState<number>(0);
  const { currentUser } = useContext(AuthContext);
  const { voteSettings } =
    useContext(VoteSettingsContext);

  const scrollableRef = useRef(null);

  const { data: usersVotes } = useQuery<
    IndexQuery<Vote>
  >(GET_VOTES_BY_USER, {
    variables: {
      userId: currentUser?.id,
    },
  });

  let userTotalVotes = -1;
  if (usersVotes !== undefined) {
    userTotalVotes = usersVotes.votes.data.reduce(
      (sum, curr) => (sum += curr.attributes.voteCount),
      0
    );
  }

  const handleNextCategory = () => {
    setCategoryIndex(curr => Math.min(categories.length, curr + 1))
  };

  const handlePrevCategory = () => {
    setCategoryIndex(curr => Math.max(0, curr - 1));
  };

  const category = categories[categoryIndex];

  return (
    <>
      <div className="flex-grow-1 overflow-y-auto" ref={scrollableRef}>
        <ScrollableElementContext.Provider value={scrollableRef.current}>
          <div className="container pb-lg-5 pb-0">
            {voteSettings &&
              voteSettings.attributes.maxVotes &&
              userTotalVotes !== -1 && (
                <p className="mb-0 mt-2 text-secondary">
                  {voteSettings.attributes.maxVotes - userTotalVotes}{" "}
                  of {voteSettings.attributes.maxVotes} votes remaining overall.
                </p>
              )}

            <div className="d-flex justify-content-between align-items-center gap-3 my-3">
              {
                !!category ? (
                  <h3 className="fs-5 fw-normal d-flex gap-2 align-items-center mb-0">
                    <span style={{ color: category.attributes.color }}>
                      <CategoryIcon category={category} size="1x" />
                    </span>
                    {category.attributes.displayName}
                  </h3>
                ) : (
                  <h3 className="fs-5 fw-normal d-flex gap-2 align-items-center mb-0">
                    <FontAwesomeIcon icon={APPROVED_ICON} size="1x" />
                    Finish Voting
                  </h3>
                )
              }
              <button
                className="btn text-secondary border-secondary order-first"
                type="button"
                onClick={handlePrevCategory}
                aria-label="Previous category"
                disabled={categoryIndex === 0}
              >
                <FontAwesomeIcon icon={ARROW_LEFT} size="xl" />
              </button>
              <button
                className="btn text-secondary border-secondary"
                type="button"
                onClick={handleNextCategory}
                aria-label="Next category"
                disabled={categoryIndex === categories.length}
              >
                <FontAwesomeIcon icon={ARROW_RIGHT} size="xl" />
              </button>
            </div>

            {
              !!category ? (
                <VoteCategory category={category} query={query} photosDataKey={photosDataKey} />
              ) : (
                <div className="my-5 d-flex flex-column align-items-center text-center">
                  <h4 className="fs-3">Voting Complete</h4>
                  <p className="fs-5">
                    You have cast votes for every category. If you wish to change any of your votes, you can do so at any time before the voting period ends.
                  </p>
                </div>
              )
            }
          </div>
        </ScrollableElementContext.Provider>
      </div>
    </>
  );
};

export const voteSubpageWithQuery = (props: VoteSubpageProps): React.FC => {
  return () => <VoteSubpage {...props} />;
};
