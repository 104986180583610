import { useMutation, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DocumentNode } from "graphql";
import { useContext, useState } from "react";
import { OverlayTrigger, Tooltip, TooltipProps } from "react-bootstrap";
import { EMPTY_STAR_ICON, STAR_ICON } from "../../constants/icons";
import { AuthContext } from "../../context/AuthContext";
import { VoteSettingsContext } from "../../context/VoteSettingsContext";
import { usePhotos } from "../../hooks/usePhotos";
import { SET_VOTE } from "../../mutations/setVote";
import { GET_ME } from "../../queries/getMe";
import { GET_VOTES_BY_USER } from "../../queries/getVotesByUser";
import { Category, IndexQuery, Photo, Vote } from "../../types";
import { PhotoDisplayContainer } from "../common/PhotoDisplayContainer";
import { GridPhoto } from "../photo/GridPhoto";
import { Lightbox } from "../photo/Lightbox";

const AddVoteTooltip = (props: TooltipProps) => (
  <Tooltip {...props}>
    Add Vote
  </Tooltip>
);

const RemoveVoteTooltip = (props: TooltipProps) => (
  <Tooltip {...props}>
    Remove Vote
  </Tooltip>
);

interface VoteSubpageProps {
  category: Category;
  query: DocumentNode;
  photosDataKey: string;
}

export const VoteCategory: React.FC<VoteSubpageProps> = ({
  category,
  query,
  photosDataKey,
}) => {
  const [seed] = useState(Date.now() % (2 ^ 31)); // fit within int32
  const { photos, loading } = usePhotos(photosDataKey, query, {
    variables: {
      categoryId: category.id,
      seed: seed,
    },
  });
  const [lightboxPhoto, setLightboxPhoto] = useState<Photo | null>(null);
  const { currentUser } = useContext(AuthContext);
  const { voteSettings } =
    useContext(VoteSettingsContext);
  const [setVote, { loading: setVoteLoading }] = useMutation(SET_VOTE);

  const { data: usersVotes } = useQuery<
    IndexQuery<Vote>
  >(GET_VOTES_BY_USER, {
    variables: {
      userId: currentUser?.id,
    },
  });

  let userTotalVotes = -1, userTotalVotesThisCategory = -1;
  if (usersVotes !== undefined) {
    userTotalVotes = usersVotes.votes.data.reduce(
      (sum, curr) => (sum += curr.attributes.voteCount),
      0
    );

    userTotalVotesThisCategory = usersVotes.votes.data
      .filter(
        (v) =>
          v.attributes.photo.data.attributes.category.data.id ===
          category.id
      )
      .reduce((sum, curr) => (sum += curr.attributes.voteCount), 0);
  }

  const handleClickGridPhoto = (photo: Photo) => {
    setLightboxPhoto(photo);
  };

  const handleVotePhoto = (p: Photo) => {
    const count = p.attributes.votes?.count ? 0 : 1;

    if (
      voteSettings === undefined ||
      userTotalVotes === -1 ||
      userTotalVotesThisCategory === -1 ||
      (voteSettings.attributes.maxVotes &&
        userTotalVotes + count > voteSettings.attributes.maxVotes) ||
      (voteSettings.attributes.maxVotesPerCategory &&
        userTotalVotesThisCategory + count >
        voteSettings.attributes.maxVotesPerCategory)
    ) {
      return;
    }

    setVote({
      variables: {
        photoId: p.id,
        count,
      },
      update(cache, { data: { setVote } }) {
        cache.modify({
          id: `PhotoEntity:${p.id}`,
          fields: {
            attributes(existing = {}) {
              return {
                ...existing,
                votes: setVote,
              };
            },
          },
        });
        cache.modify({
          id: `UsersPermissionsUser:${currentUser?.id}`,
          fields: {
            voteBalance(existing = 0) {
              return existing + count - setVote.count;
            },
          },
        });
      },
      refetchQueries: [GET_ME, GET_VOTES_BY_USER],
    });
  };

  return (
    <>
      {voteSettings &&
        voteSettings.attributes.maxVotesPerCategory &&
        userTotalVotesThisCategory !== -1 && (
          <p className="text-secondary">
            {voteSettings.attributes.maxVotesPerCategory -
              userTotalVotesThisCategory}{" "}
            of {voteSettings.attributes.maxVotesPerCategory} votes
            remaining in this category.
          </p>
        )}

      <PhotoDisplayContainer
        loading={loading}
        photos={photos}
        showBottomOfFeed={false}
      >
        <div className="photo-grid voting-grid">
          {photos.map((p) => (
            <div className="d-flex flex-column mb-3" key={p.id}>
              <GridPhoto
                key={p.id}
                photo={p}
                onClick={() => handleClickGridPhoto(p)}
                showVotes={true}
              />
              <p className="fs-5 mb-1">{p.attributes.name}</p>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={p.attributes.votes?.count ? RemoveVoteTooltip : AddVoteTooltip}
              >
                {p.attributes.votes?.count ? (
                  <button
                    className="btn text-secondary border-secondary bg-transparent"
                    type="button"
                    disabled={setVoteLoading}
                    onClick={() => handleVotePhoto(p)}
                  >
                    <FontAwesomeIcon icon={STAR_ICON}
                      size="2x"
                      color="goldenrod" />
                  </button>
                ) : (
                  <button
                    className="btn text-secondary border-secondary bg-transparent"
                    type="button"
                    disabled={
                      setVoteLoading ||
                      userTotalVotes === voteSettings?.attributes.maxVotes ||
                      userTotalVotesThisCategory === voteSettings?.attributes.maxVotesPerCategory
                    }
                    onClick={() => handleVotePhoto(p)}
                  >
                    <FontAwesomeIcon icon={EMPTY_STAR_ICON}
                      size="2x"
                      color="goldenrod" />
                  </button>
                )}
              </OverlayTrigger>
            </div>
          ))}
        </div>
      </PhotoDisplayContainer>

      <Lightbox
        show={lightboxPhoto !== null}
        image={lightboxPhoto?.attributes.photo.data}
        alt={lightboxPhoto?.attributes.name ?? "lightbox photo"}
        onHide={() => {
          setLightboxPhoto(null);
        }}
      />
    </>
  );
}
